import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
import maincomponent from "./components/main-component.vue";
import admincomponent from "./components/admin-component.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import BootstrapVue3 from "bootstrap-vue-3";
import { Vue3Mq } from "vue3-mq";
import { BIconCaretRightFill } from "bootstrap-icons-vue";

import "../node_modules/bootstrap/dist/css/bootstrap.css";

const routes = [
  {
    path: "/",
    name: "Home",
    component: maincomponent,
  },
  {
    path: "/admin",
    name: "Admin",
    component: admincomponent,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});



const app = createApp(App);

app.use(router);
app.use(VueAxios, axios);
app.use(BootstrapVue3);
app.use(Vue3Mq);
app.component("BIconCaretRightFill", BIconCaretRightFill);

app.mount("#app");
