<template>
  <div>
    <b-card title="Admin" style="max-width: 30em; margin:auto" class="mb-2">
      <div>
        <div>
          <label for="password" style="margin-left:12px">Password</label>
          <b-form-input id="password" style="width:80%; margin-left:12px" class="font-KiaSignatureBold" v-model="password" type="password"></b-form-input>
        </div>
        <div>
          <p>Upload the Sold Order report</p>
          <div>
            <input type="file" class="btn" @change="previewFiles" />
          </div>
          <div>
            <input type="button" style="margin-left: 13px" v-on:click="submitFile()" value="Submit">
          </div>
          <div>
            {{ status }}
          </div>
        </div>
        <div style="margin-top: 50px">
          <p>Upload the Pipeline - Retail</p>
          <div>
            <input type="file" class="btn" @change="previewFilesFileVehicleDetailsReporttus" />
          </div>
          <div>
            <input type="button" value="Submit" style="margin-left: 13px" v-on:click="submitFileVehicleDetailsReport()">
          </div>
          <div>
            {{ statusFileVehicleDetailsReport }}
          </div>
        </div>
        <div style="margin-top: 50px">
          <p>Upload model translation</p>
          <div>
            <input type="file" class="btn" @change="previewModelTranslation" />
          </div>
          <div>
            <input type="button" value="Submit" style="margin-left: 13px" v-on:click="submitModelTranslation()">
          </div>
          <div>
            {{ statusModelTranslation }}
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'main-component',
  data() {
    return {
      file: '',
      status: '',
      fileFileVehicleDetailsReport: '',
      statusFileVehicleDetailsReport: '',
      fileModelTranslation: '',
      statusModelTranslation: '',
      password: ''
    }
  },
  methods: {
    async submitFile() {
      if (!this.file) {
        this.status = "Please select file";
        return;
      }

      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('password', this.password);
      let url = `${window.baseUrl}api/Data`;
      let postConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      let response = await this.axios.post(url, formData, postConfig);
      this.status = response.data;
    },
    async submitFileVehicleDetailsReport() {
      if (!this.fileFileVehicleDetailsReport) {
        this.statusFileVehicleDetailsReport = "Please select file";
        return;
      }

      let formData = new FormData();
      formData.append('file', this.fileFileVehicleDetailsReport);
      formData.append('password', this.password);
      let url = `${window.baseUrl}api/Data/FileVehicleDetailsReport`;
      let postConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      let response = await this.axios.post(url, formData, postConfig);
      this.statusFileVehicleDetailsReport = response.data;
    },
    async submitModelTranslation() {
      if (!this.fileModelTranslation) {
        this.statusModelTranslation = "Please select file";
        return;
      }

      let formData = new FormData();
      formData.append('file', this.fileModelTranslation);
      formData.append('password', this.password);
      let url = `${window.baseUrl}api/Data/ModelTranslation`;
      let postConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      let response = await this.axios.post(url, formData, postConfig);
      this.statusModelTranslation = response.data;
    },
    previewModelTranslation(event) {
      this.fileModelTranslation = event.target.files[0];
    },
    previewFilesFileVehicleDetailsReporttus(event) {
      this.fileFileVehicleDetailsReport = event.target.files[0];
    },
    previewFiles(event) {
      this.file = event.target.files[0];
    }
  }
}
</script>

<style scoped>
</style>
