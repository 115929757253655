<template>
  <div>
    <MqResponsive target="md+">
      <div class="row" style="width:100%">
        <div class="col header-title-parent">
          <div class="header-title font-regular">
            <h4>{{ headerTitle }}</h4>
          </div>
        </div>
        <div class="col">
          <div class="app-logo">
            <img src="logo.png" width="400">
          </div>
        </div>
      </div>
    </MqResponsive>
    <MqResponsive target="sm-">
      <div class="app-logo">
        <img src="logo.png" width="200">
      </div>
    </MqResponsive>
    <div class="mainform" style="">
      <b-card v-if="!orederItem" class="input-main-form mb-2" style="max-width: 30em; margin:auto">
        <h4 class="title">
          Sold Order Tracking
        </h4>
        <div>
          <label for="fullName" class="label font-type-book">Full Name </label>
          <b-form-input class="font-type-book" v-model="lastName" id="fullName" placeholder="Full Name"></b-form-input>
        </div>
        <div>
          <label for="order" class="label font-type-book">Sold Order # </label>
          <b-form-input class="font-type-book" v-model="order" id="order" placeholder="Sold Order"></b-form-input>
        </div>
        <div v-if="!loading">
          <b-button class="button font-type-book" v-on:click="showData">Show</b-button>
        </div>
        <div v-if="loading">
          <b-button class="button font-type-book" disabled>
            <b-spinner small type="grow"></b-spinner>
            Show
          </b-button>
        </div>
      </b-card>
      <div class="order-details">
        <div v-if="pressed && !orederItem" style="max-width: 30em; margin:auto">
          <b-alert show variant="danger">{{errorMessage}}</b-alert>
        </div>
        <div v-if="orederItem">
          <b-card style="max-width: 60em; margin:auto" class="mb-2">
            <div class="font-regular">
              <h1>
                <MqResponsive target="md+">
                  {{ orederItem.customerName }}'s {{ orederItem.modelYear }} {{ orederItem.marketName }}
                </MqResponsive>
                <MqResponsive target="sm-">
                  <p>{{ orederItem.customerName }}'s</p>
                  <p>{{ orederItem.modelYear }} {{ orederItem.marketName }}</p>
                </MqResponsive>
              </h1>
            </div>
            <div class="sold-order-order font-type-regular">
              <h3>
                Order # {{ orederItem.orderNumber }}
              </h3>
            </div>
            <MqResponsive target="sm-">
              <div class="timeline font-type-book">
                <div class="swiper-slide" v-for="item in statuses">
                  <div v-if="!item.selected">
                    <div class="status small">
                      <span>{{ item.title }}</span>
                    </div>
                  </div>
                  <div v-if="item.selected">
                    <div class="status small selected">
                      <span>{{ item.title }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </MqResponsive>
            <MqResponsive target="md+">
              <div class="timeline font-type-book">
                <div class="swiper-slide" v-for="item in statuses">
                  <div v-if="!item.selected">
                    <div class="status">
                      <span>{{ item.title }}</span>
                    </div>
                  </div>
                  <div v-if="item.selected">
                    <div class="status selected">
                      <span>{{ item.title }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </MqResponsive>
            <div class="font-regular">
              <h3>
                <MqResponsive target="sm-">
                  <p>Current Status: </p>
                  <p style="display: inline" class="order-status-value">{{ selectedStatus }}</p>
                </MqResponsive>
                <MqResponsive target="md+">
                  Current Status:
                  <p style="display: inline" class="order-status-value">{{ selectedStatus }}</p>
                </MqResponsive>
              </h3>
            </div>
            <div class="font-regular updated-date">
              <h3>
                Last Updated: {{ updatedDate }}
              </h3>
            </div>
            <div>
              <div class="what-does-each-status-mean-section">
                <b-tabs content-class="mt-3 what-does-each-status-mean-body" fill title-item-class="tab-title-class">
                  <b-tab title="Pending Fulfillment" active>
                    <p>This status means we've confirmed your order at the dealership level and have registered it in the manufacturing queue with Toyota Canada.
Completing this step is required to generate your Sold Order number to access this site.</p>
                  </b-tab>
                  <b-tab title="Filled">
                    <p>Your vehicle remains in the queue to be assembled, but based on available parts and materials, your vehicle is officially a part of the scheduled
manufacturing plan.</p>
                  </b-tab>
                  <b-tab title="In Production">
                    <p>Congratulations! Your vehicle has begun its journey through the assembly line. Approximately thirty thousand parts are coming together to
become your next new vehicle. Once it completes its journey through the assembly line, it will go to the next stage which is Transit.</p>
                  </b-tab>
                  <b-tab title="In Transit">
                    <p>
                      Your vehicle is on the way from the manufacturing facility to our dealership. Unfortunately, our system can't offer you any more specific tracking
than this. As a global manufacturer, Toyota has manufacturing plants in many different countries. Some vehicles are built as close as Cambridge,
Ontario while others are built in Japan and shipped overseas. Now would be a great time to ensure you've reviewed all <a target="_blank" href="https://www.whitbytoyota.com/en/accessories">accessories</a> available for
your vehicle and order anything you'd like to have installed on your vehicle when it arrives at our dealership. Some accessories are on back-order,
but customers who order them while their vehicle is in transit usually don't experience additional wait times.</p>
                  </b-tab>
                  <b-tab title="Delivered">
                    <p>This status means your vehicle has been delivered to our dealership or will be arriving soon. Once your vehicle lands on our lot, our technicians
have to complete a Pre-Delivery Inspection (PI). Your vehicle will receive priority booking but will have to be entered into the queue. Please
contact your Product Advisor to arrange pickup if they haven't reached out already.</p>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { MqResponsive } from "vue3-mq";

export default {
  name: 'main-component',
  components: {
    MqResponsive
  },
  data() {
    return {
      //lastName: 'ROSE MARIE SNOOKS',
      //order: 'SO0000450144',
      lastName: '',
      order: '',
      orederItem: null,
      pressed: false,
      loading: false,
      headerTitle: '',
      statuses: [],
      selectedStatus: '',
      updatedDate: '',
      errorMessage: 'Not found'
    }
  },
  methods: {
    getStatus(item) {
      return item.status;
    },
    initStatuses(status) {
      this.statuses = [
        { title: 'Pending Fulfillment' },
        { title: 'Filled' },
        { title: 'In Production' },
        { title: 'In Transit' },
        { title: 'Delivered' }
      ];

      for (let i = 0; i < this.statuses.length; i++) {
        if (this.statuses[i].title.toLowerCase() == status.toLowerCase()) {
          this.statuses[i].selected = true;
          this.selectedStatus = this.statuses[i].title;
        }
      }
    },
    async showData() {
      this.loading = true;
      this.orederItem = null;
      try {
        if (this.lastName.trim().length<3){
          this.errorMessage = 'Please enter at least 3 chars';
          return;
        }
        let url = `${window.baseUrl}api/Data/${this.lastName.trim()}/${this.order.trim()}`;
        const response = await fetch(url);
        this.orederItem = await response.json();
        if (!this.orederItem){
          this.errorMessage = 'Not Found';
          return;
        }
        
        this.headerTitle = "Sold Order Tracking";
        var status = this.orederItem.calculatedStatus;

        this.updatedDate = moment(this.orederItem.updatedDate).format("MMMM DD")

        if (this.orederItem){
          const searchURL = new URL(window.location);
          searchURL.searchParams.set('order', this.order.trim());
          searchURL.searchParams.set('fullname', this.lastName.trim());
          window.history.pushState({}, '', searchURL);
        }

        this.initStatuses(status);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.pressed = true;
      }
    }
    
  },
  created(){
    const searchURL = new URL(window.location);
    this.order = searchURL.searchParams.get('order');
    this.lastName = searchURL.searchParams.get('fullname');
    if (this.order&&this.lastName){
      this.showData();
    }
  }
}
</script>

<style scoped>
@media (max-width: 400px) {
  .small-size {
    margin-top: 200px;
  }
}

.font-type-book {
  font-family: ToyotaType-Book, Avenir, Helvetica, Arial, sans-serif;
}

.font-regular {
  font-family: ToyotaType-Regular, Avenir, Helvetica, Arial, sans-serif;
}

.font-Bold {
  font-family: ToyotaType-Bold, Avenir, Helvetica, Arial, sans-serif;
}

.font-Semibold {
  font-family: ToyotaType-Semibold, Avenir, Helvetica, Arial, sans-serif;
}

.order-details .sold-order-title h1 {
  font-family: ToyotaType-Regular, Avenir, Helvetica, Arial, sans-serif;
}

.updated-date {
  color: #808285;
  margin-top: 20px;
}

.order-details .sold-order-status h3 {
  font-weight: bold !important;
}

.order-status-value {
  color: #136FC2;
}

.order-details {
  text-align: center;
  color: #2F3D48;
}

.order-details .card {
  border: 0px;
}

.header-title-parent {
  position: relative;
}

.header-title {
  left: 50px;
  position: absolute;
  top: 35%;
}

.app-logo {
  text-align: right;
}

.input-main-form {
  color: #ffffff;
}

.input-main-form .button {
  width: 100%;
  margin-top: 25px;
  background: var(--brand-red-color);
}

.input-main-form .title {
  padding-bottom: 5px;
}

.input-main-form .label {
  margin-top: 20px;
  padding-bottom: 10px;
}

.form-control {
  background: var(--brand-black-80-color);
  color: #ffffff;
}

.form-control:focus {
  background: var(--brand-black-80-color);
  color: #ffffff;
}

.card.input-main-form {
  background: var(--brand-black-80-color);
  border-radius: 1.2em;
  padding: 20px;
}

body {
  background: #e8eeff;
}

#app {
  padding: 50px 0;
}

.timeline {
  margin: 50px 0;
  list-style-type: none;
  display: flex;
  padding: 0;
  text-align: center;
}

.status.small {
  padding: 0px 10px;
}

.status {
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  border-top: 12px solid #879fb7;
  position: relative;
  transition: all 200ms ease-in;
}

/* #25527c */
.status.selected {
  border-top: 12px solid #879fb7;
  font-weight: bolder;
}

.status.selected span {
  font-weight: 1000;
}

.status span {
  padding-top: 20px;
}

.status span:before {
  content: '';
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 30px;
  border: 15px solid #879fb7;
  position: absolute;
  top: -20px;
  left: 42%;
  transition: all 200ms ease-in;
}

.status.selected span:before {
  /* border: 8px solid #879fb7; */
  border: 8px solid #879fb7;
}

.swiper-slide {
  width: 20%;
}

.mainform {
  margin-top: 20px;
}

.expando-btn {
  background-color: #e5e6e8;
  border-color: #e5e6e8;
  border-radius: 0px;
  color: black;
  width: 90%;
  padding-top: 10px;
}

.expando-btn:hover {
  background-color: #e5e6e8;
  border-color: #e5e6e8;
  border-radius: 0px;
  color: black;
  width: 90%;
  padding-top: 10px;
}

.expando-btn:focus {
  background-color: #e5e6e8;
  border-color: #e5e6e8;
  border-radius: 0px;
  color: black;
  width: 90%;
  padding-top: 10px;
}



/* #136FC2 */
/* #2F3D48 */
</style>
