<template>
  <div>
    <!-- <b-nav>
      <b-nav-item href="/">Home</b-nav-item>
      <b-nav-item href="/admin">Admin</b-nav-item>
    </b-nav> -->

    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>

<style>
:root {
  --title-color: #2c3e50;
  --brand-red-color: #EB0A1E;
  --brand-black-100-color: #000000;
  --brand-black-80-color: #58595B;
  --brand-black-60-color: #939598;
  --brand-black-50-color: #808285;
  --brand-black-30-color: #808285;
  --brand-white: #FFFFFF;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--title-color)
}

.what-does-each-status-mean-section {
  padding-top: 30px;
}

.what-does-each-status-mean-body {
  text-align: left;
}

.nav-link.active {
  color: black !important;
}

a {
  color: #136FC2 !important;
}

.nav-link {
  color: #136FC2 !important;
}

@font-face {
  font-family: "ToyotaType-Regular";
  src: local("ToyotaType-Regular"),
    url('./fonts/ToyotaType-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "ToyotaType-Bold";
  src: local("ToyotaType-Bold"),
    url('./fonts/ToyotaType-Bold.ttf') format("truetype");
}

@font-face {
  font-family: "ToyotaType-Semibold";
  src: local("ToyotaType-Semibold"),
    url('./fonts/ToyotaType-Semibold.ttf') format("truetype");
}

@font-face {
  font-family: "ToyotaType-Book";
  src: local("ToyotaType-Book"),
    url('./fonts/ToyotaType-Book.ttf') format("truetype");
}
</style>
